<template>
  <div class="queue-detail-desc-root-container">
    <span style="font-size: 18px; font-weight: bold">备注</span>
    <Divider />
    <p>{{ dataSource }}</p>
  </div>
</template>

<script>
export default {
  props: {
    dataSource: String,
  },
};
</script>

<style scoped>
.queue-detail-desc-root-container {
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>