<template>
  <my-drawer ref="dateDrawer" title="付款日期设置">
    <template v-slot:main>
      <div style="display:flex;flex-direction:column;">
        <my-datepicker ref="formDesc" title="付款日期" :rows="10" v-model="pay_time" />
      </div>
    </template>
    <template v-slot:footer>
      <Button size="large" type="primary" style="width:100px;" @click="submit">保存</Button>
    </template>
  </my-drawer>
</template>

<script>
import MyDatePicker from "../../../components/Form/MyDatePicker";
import MyDrawer from "../../../components/MyDrawer";
export default {
  components: {
    "my-drawer": MyDrawer,
    "my-datepicker": MyDatePicker,
  },
  data() {
    return {
      pay_time: new Date(),
    };
  },
  methods: {
    open(pay_time) {
      this.pay_time = pay_time;
      this.$refs.dateDrawer.open();
    },
    submit() {
      this.$refs.dateDrawer.close();
    },
  },
};
</script>

<style scoped>
</style>