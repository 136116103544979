<template>
  <div class="queue-detail-root-container">
    <div class="queue-detail-header-container">
      <span class="queue-detail-header-title"
        >付款处理队列ID：{{ queueDetail.queue_id }}</span
      >
      <div class="queue-detail-header-right-container">
        <Button
          type="text"
          class="queue-transaction-btn"
          size="large"
          icon="md-contact"
          @click="showPayModal"
          >查看每一笔交易</Button
        >
        <Button
          v-if="queueDetail.type != 2 && canPay && queueDetail.state == 1"
          type="success"
          size="large"
          @click="pay"
          >立即完成支付</Button
        >
        <span
          v-else-if="queueDetail.type != 2 && !canPay && queueDetail.state == 1"
          style="color: #ffffff; font-size: 16px; font-weight: bold"
          >资金不足以完成支付</span
        >
        <!-- <Button
          v-else-if="queueDetail.state == 3"
          type="success"
          size="large"
          @click="getPdf"
          >下载交易回单</Button
        > -->
        <img @click="close" :src="icons.upArrow" class="queue-detail-arrow" />
      </div>
    </div>
    <div class="queue-detail-main-container">
      <div class="queue-plan-item-container">
        <plan-item
          v-for="item in planList"
          :key="item.id"
          :data-source="item"
          @click.native="item.isSelected = !item.isSelected"
        ></plan-item>
      </div>
      <div class="queue-detail-bottom-container">
        <div class="queue-detail-left-container">
          <queue-detail-info
            style="margin-bottom: 30px"
            :data-source="queueDetail"
          />
          <queue-pay-type :data-source="card" />
        </div>
        <div class="queue-detail-right-container">
          <queue-not-enough
            v-if="!canPay && queueDetail.state == 1"
            :data-source="notEnough"
            style="margin-bottom: 30px"
          />
          <!-- <queue-detail-date
            v-if="type == 2"
            style="margin-bottom: 30px"
          /> -->
          <queue-detail-desc :data-source="queueDetail.more" />
        </div>
      </div>
    </div>
    <can-pay-modal ref="canPayModal" @close="close" />
    <can-not-pay-modal ref="canNotPayModal" />
    <pay-list-modal ref="payListModal" :data-source="payList" />
    <!-- <queue-pay-modal ref="queuePayModal" /> -->
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import * as icon from "../../common/icon";
import PlanItem from "../Plan/Item/PlanItem";
import CanPayModal from "./Modal/CanPayModal";
import QueuePayType from "./Panel/QueuePayType";
import QueuePayModal from "./Modal/QueuePayModal";
import QueueNotEnough from "./Panel/QueueNotEnough";
import CanNotPayModal from "./Modal/CanNotPayModal";
import QueueDetailInfo from "./Panel/QueueDetailInfo";
import QueueDetailDate from "./Panel/QueueDetailDate";
import QueueDetailDesc from "./Panel/QueueDetailDesc";
import PayListModal from "../../components/Modal/PayListModal";
export default {
  components: {
    "plan-item": PlanItem,
    "can-pay-modal": CanPayModal,
    "can-not-pay-modal": CanNotPayModal,
    "pay-list-modal": PayListModal,
    "queue-pay-type": QueuePayType,
    "queue-pay-modal": QueuePayModal,
    "queue-not-enough": QueueNotEnough,
    "queue-detail-info": QueueDetailInfo,
    "queue-detail-date": QueueDetailDate,
    "queue-detail-desc": QueueDetailDesc,
  },
  data() {
    return {
      icons: icon,
      queueId: 0,
      type: 2, // 1 立即支付 2 日期支付
      isComplete: false,
      card: {
        name: "",
        issuer: "",
        img_url: null,
        frozen_amount: 0,
        salary_amount: 0,
        service_amount: 0,
      },
      queueDetail: {
        name: "",
        company_id: 0,
        salary_amount: 0,
        service_amount: 0,
      },
      notEnough: {
        // queue_id: 0,
        total_amount: 0,
      },
      payList: [],
      planList: [],
    };
  },
  mounted() {
    this.queueId = this.$router.currentRoute.params.id;

    this.refresh();
  },
  methods: {
    ...mapActions({
      planGetListAction: "planGetList",
      queueGetPdfAction: "queueGetPdf",
      queueGetDetailAction: "queueGetDetail",
      planGetPayListAction: "planGetPayList",
      assetGetCardDetailAction: "assetGetCardDetail",
    }),
    close() {
      this.$router.push({ name: "queue" });
    },
    showPayModal() {
      this.$refs.payListModal.open("全部待付款列表");
    },
    pay() {
      if (this.canPay) {
        this.$refs.canPayModal.open(this.queueDetail);
      } else {
        this.$refs.canNotPayModal.open(this.queueDetail.queue_id);
      }
    },
    refresh() {
      this.queueGetDetailAction({
        employer_id: localStorage.getItem("employer_id"),
        queue_id: this.queueId,
      })
        .then((res) => {
          res.pay_plan_id_arr = JSON.parse(res.pay_plan_id_arr);
          res.pay_time = moment(res.pay_time).format("YYYY/MM/DD HH:mm");
          res.create_time = moment(res.create_time).format("YYYY/MM/DD HH:mm");
          this.queueDetail = res;

          this.assetGetCardDetailAction({
            employer_card_id: this.queueDetail.employer_card_id,
            company_id: this.queueDetail.company_id,
          })
            .then((res) => {
              this.card = res;

              // this.notEnough.queue_id = this.queueId;
              this.notEnough.total_amount =
                this.queueDetail.salary_amount +
                this.queueDetail.service_amount -
                (this.card.salary_amount + this.card.service_amount);
            })
            .catch((error) => {
              console.error(error);
              this.$Message.error(error);
            });

          this.planGetPayListAction({
            employer_id: localStorage.getItem("employer_id"),
            pay_plan_id_arr: this.queueDetail.pay_plan_id_arr,
          })
            .then((res) => {
              this.payList = res;
            })
            .catch((error) => {
              this.$Message.error(error);
            });

          this.planGetListAction({
            employer_id: localStorage.getItem("employer_id"),
            pay_plan_id_arr: this.queueDetail.pay_plan_id_arr,
            orderby_type: 1,
            // state: 2,
          })
            .then((res) => {
              res.map((item) => {
                item.isValid = true;
                item.update_time = moment(item.update_time).format(
                  "YYYY-MM-DD HH:mm"
                );
              });
              this.planList = res;
            })
            .catch((error) => {
              this.$Message.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        });
    },
    getPdf() {
      this.queueGetPdfAction({
        employer_id: localStorage.getItem("employer_id"),
        queue_id: this.queueId,
      })
        .then((res) => {
          // console.log(res);
          window.open(res[0].file_path);
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
  computed: {
    canPay: function () {
      return this.card.salary_amount >= this.queueDetail.salary_amount;
    },
  },
};
</script>

<style scoped>
.queue-detail-root-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #f7f7f7;
  overflow-y: scroll;
  /* overflow: hidden; */
}
.queue-detail-header-container {
  background-color: #313f4a;
  height: 60px;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 50px;
  padding-left: 50px;
}
.queue-detail-header-right-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.queue-detail-arrow {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: 50px;
}
.queue-detail-header-title {
  margin-left: 40px;
  color: #fff;
  font-size: 15px;
}
.queue-transaction-btn {
  color: #fff;
  margin-right: 20px;
}
.queue-transaction-btn:hover {
  color: #000;
}
.queue-detail-main-container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* overflow-y: scroll; */
  padding: 60px;
}
.queue-plan-item-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-x: scroll;
  margin-bottom: 20px;
}
.queue-detail-bottom-container {
  display: flex;
  flex-direction: row;
}
.queue-detail-left-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.queue-detail-right-container {
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  width: 500px;
}
</style>