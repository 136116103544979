<template>
  <div class="queue-detail-pay-type-container">
    <span style="font-size: 20px; font-weight: bold; margin-bottom: 20px"
      >付款方式</span
    >
    <queue-item-card :data-source="dataSource" />
  </div>
</template>

<script>
import QueueItemCard from "../Item/QueueItemCard";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    "queue-item-card": QueueItemCard,
  },
  // data() {
  //   return {
  //     card: {
  //       cardImgUrl: null,
  //       cardName: "游用工卡",
  //       cardPublisher: "山东游隼企业管理公司",
  //       cardAmount: 500000,
  //       cardFrozen: 100000,
  //       cardRemain: 400000,
  //     },
  //   };
  // },
};
</script>

<style scoped>
.queue-detail-pay-type-container {
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
</style>