<template>
  <div class="queue-detail-date-container">
    <span style="margin-bottom:10px;font-size:18px;font-weight:bold">计划于{{pay_time_format}}提交银行处理</span>
    <Button type="text" icon="md-calendar" style="color:#1296DB;padding:0px" @click="open">设置一个其他日期</Button>
    <queue-detail-date-drawer ref="queueDetailDateDrawer" />
  </div>
</template>

<script>
import QueueDetailDateDrawer from "../Drawer/QueueDetailDateDrawer";
export default {
  components: {
    "queue-detail-date-drawer": QueueDetailDateDrawer,
  },
  data() {
    return {
      pay_time_format: "2020/09/20 9:00",
      pay_time: new Date(),
    };
  },
  methods:{
    open(){
      this.$refs.queueDetailDateDrawer.open(this.pay_time);
    }
  }
};
</script>

<style scoped>
.queue-detail-date-container {
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>