<template>
  <Modal v-model="isShowModal" fullscreen footer-hide>
    <div class="queue-pay-modal-root-container">
      <div class="queue-pay-modal-header-container">
        <div class="queue-pay-modal-title-container">
          <span style="font-size: 32px; font-weight: bold"
            >全部待支付的列表</span
          >
          <span style="font-size: 14px; color: #4f4e4e; margin-top: 10px">
            您已选中{{ planList.length }}个付款计划：
            <span v-for="item in planList" :key="item.id"
              >#{{ item.guid }}#</span
            >
          </span>
        </div>
        <div
          class="queue-pay-modal-title-container"
          style="align-items: flex-end"
        >
          <fee :amount="totalAmount" :primarySize="32" :secondarySize="28" />
          <span style="font-size: 13px; font-weight: bold; margin-top: 10px"
            >{{ payList.length }}笔，合计金额</span
          >
        </div>
      </div>

      <pay-list :dataSource="payList" />
    </div>
  </Modal>
</template>

<script>
import PayList from "../../../components/List/PayList";
import Fee from "../../../components/Fee";
export default {
  components: {
    fee: Fee,
    "pay-list": PayList,
  },
  data() {
    return {
      isShowModal: false,

      count: 100,
      totalAmount: 75000,

      planList: [
        {
          id: 1,
          guid: "123123123123",
        },
      ],

      payList: [],
    };
  },
  methods: {
    open() {
      this.isShowModal = true;
    },
  },
};
</script>

<style scoped>
.queue-pay-modal-root-container {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  padding-left: 285px;
  padding-right: 150px;
  overflow: hidden;
}
.queue-pay-modal-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 100px;
}
.queue-pay-modal-title-container {
  display: flex;
  flex-direction: column;
}
</style>