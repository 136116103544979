<template>
  <div class="queue-detail-info-container">
    <div class="queue-detail-row" style="margin-bottom: 20px">
      <span style="font-size: 15px">名称</span>
      <span style="font-size: 15px; font-weight: bold">{{dataSource.name}}</span>
    </div>
    <div class="queue-detail-row" style="margin-bottom: 20px">
      <span style="font-size: 15px">交易类型</span>
      <span style="font-size: 15px; font-weight: bold">付款出账</span>
    </div>
    <div class="queue-detail-row" style="margin-bottom: 20px">
      <span style="font-size: 15px">操作者</span>
      <div class="queue-detail-row" style="justify-content: flex-start">
        <my-avatar
          :size="30"
          :name="dataSource.create_user_name"
          :img_url="dataSource.create_user_img_url"
        />
        <span style="font-size: 15px; font-weight: bold; margin-left: 10px">{{
          dataSource.create_user_name
        }}</span>
      </div>
    </div>
    <div class="queue-detail-row" style="margin-bottom: 80px">
      <span style="font-size: 15px">创建时间</span>
      <span style="font-size: 15px; font-weight: bold">{{
        dataSource.create_time
      }}</span>
    </div>
    <div class="queue-detail-row" style="margin-bottom: 20px">
      <span style="font-size: 15px">全部{{ dataSource.count }}笔，金额</span>
      <fee
        :amount="dataSource.salary_amount"
        :primarySize="18"
        :secondarySize="18"
      />
    </div>
    <div class="queue-detail-row">
      <span style="font-size: 15px">服务费</span>
      <fee
        :amount="dataSource.service_amount"
        :primarySize="18"
        :secondarySize="18"
      />
    </div>
    <Divider />
    <div class="queue-detail-column">
      <span style="font-size: 23px; font-weight: bold">合计</span>
      <fee :amount="totalAmount" :primarySize="32" :secondarySize="29" />
    </div>
  </div>
</template>

<script>
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
export default {
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
  },
  props: {
    dataSource: Object,
  },
  computed: {
    totalAmount: function () {
      return this.dataSource.salary_amount + this.dataSource.service_amount;
    },
  },
};
</script>

<style scoped>
.queue-detail-info-container {
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.queue-detail-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.queue-detail-column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
</style>